import { createAction, props } from '@ngrx/store';

import { LogDetails } from '../../models';

export const loadRequest = createAction(
  '[Log Details] Load Request',
  props<{
    properties: number[];
    operationCode: string;
    accommodationId: number;
    queryString: string;
  }>(),
);

export const loadSuccess = createAction(
  '[Log Details] Load Success',
  props<{ logDetails: LogDetails }>(),
);

export const loadFailure = createAction(
  '[Log Details] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Log Details] Reset State');
