import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LogDetailsStoreEffects } from './effects';
import { logDetailsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('logDetails', logDetailsReducer),
    EffectsModule.forFeature([LogDetailsStoreEffects]),
  ],
})
export class LogDetailsStoreModule {}
