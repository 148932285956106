import { Action, createReducer, on } from '@ngrx/store';
import moment from 'moment';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { logDetails }) => ({
    ...state,
    data: {
      ...logDetails,
      accommodations: logDetails.accommodations.map((accommodation) => ({
        ...accommodation,
        days: (accommodation.days || [])
          .slice()
          .sort((a, b) => moment(b.date).diff(moment(a.date), 'days')),
      })),
    },
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function logDetailsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
