import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { LoadLogsRequest, Logs } from '../../models';

export const loadRequest = createAction(
  '[Logs] Load Request',
  props<LoadLogsRequest>(),
);

export const loadSuccess = createAction(
  '[Logs] Load Success',
  props<{ logs: Logs; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Logs] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Logs] Reset State');
