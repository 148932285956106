import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { LogsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class LogDetailsStoreEffects {
  constructor(
    private dataService: LogsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties, operationCode, accommodationId, queryString }) =>
        this.dataService
          .loadDetails(properties, operationCode, accommodationId, queryString)
          .pipe(
            map((response: IResponseSuccess) =>
              fromActions.loadSuccess({
                logDetails: { ...response.data, operationCode },
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );
}
