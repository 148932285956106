import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Logs } from '../../models';

export interface State {
  data: Logs;
  pagination: IPagination;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: {},
  pagination: null,
  isLoading: false,
  error: null,
};
